import React from "react";
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Welcome(props)  {
    const { t } = useTranslation()
  

  //const dispatch = useDispatch()
//       const Banner  = {
//     position: 'absolute',
//     top: '50%',
//     right: '0',
//     MsTransform: 'translateY(-50%)',
//     transform: 'translateY(-50%)',

//     padding: '32px',
//     background: 'var(--gray-darker,#313639)',
//     color: 'var(--brand-white,#fff)',
        
// }
  


    return (
        <>
            {/* <div className="grid-container"> */}

            <div className="landing-page-layout">
                <header className="jumbotron jumbo full">
                    <div className="grid grid-container--lg">
                        <div className="column--xs-12 column--md-6">
                            <h1>{t("Hello.Welcome")}</h1>
                            <span className="h2 sub-heading">{t("Hello.WelcomeIntro")}</span>
                        </div>
                    </div>
                </header>
                <aside className="quicklinks-panel pull-right col-xs-12 col-md-5 col-xl-4 col-xxl-3">
                    <p>
                        {t("Hello.Intro")}
                    </p>
                    <ul className="fa-ul quicklinks">
                        <li>
                            <i className="fa-li fa fa-long-arrow-right"></i>
                            <Link to="/credentials">
                                {t("Hello.B1")}
                            </Link>
                        </li>
                        <li>
                            <i className="fa-li fa fa-long-arrow-right" ></i>
                            <a onClick={() => props.ctx.goto(props.ctx.slides[props.ctx.number +1], true)}> {t("Hello.B2")} </a>
                        </li>
                        {/* <li>
                            <i className="fa-li fa fa-long-arrow-right"></i>
                            <Link to="/rebuild">
                                {t("Hello.B3")}
                            </Link>
                        </li> */}
                    </ul>
                </aside>
                {/* <div className="col-sm-12 col-md-12 col-lg-12">
                        <img className="img" src="img/control/arrow-gif-blue-down.gif" alt="Swipe down" width="100" height="100"/>
                </div>  */}

            </div>
    </>
        
      );
  };

