import React from "react";
import Fullpage, { FullPageSections, FullpageSection, FullpageNavigation, FullpageContext } from '@ap.cx/react-fullpage'
import { useTranslation } from 'react-i18next'

import EDMSetup from "../../sharedcomponents/EDMSetup"
import StepsRebuild from "./StepsRebuild"

import StepL from "../../sharedcomponents/StepL"
import StepR from "../../sharedcomponents/StepR"



export default function LaptopRebuild()  {

    const { t } = useTranslation()
    const [Dell, setDell] = React.useState(false);
    const [Lenovo, setLenovo] = React.useState(false);

    function handleDell(ctx) {
        // e.preventDefault()
        setDell(true)
        setLenovo(false)
        // ctx.goto(ctx.slides[ctx.number +1], true)
    }

    function HandleLenovo(ctx) {
        // e.preventDefault()
        setDell(false)
        setLenovo(true)
        // ctx.goto(ctx.slides[ctx.number +1], true)
    }
    

    
    const StepStyle = {
        //paddingTop: '6em',
        // display: 'flex', 
        //width: '100vh', 
        //height: '100vh', 
        justifyContent: 'center', 
        alignItems: 'center'
    }

    const PageStyle = {

        alignItems: 'center', 
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
    
    const ArrowUp ={
        position: 'fixed',
        top: '0',
        marginTop: '5em',
        //width: '100%',
        //height: '0',
        borderRadius: '50%',
        
        zIndex: '2',
        border: 'none',
        outline:'none',
        "&:hover": {
            'outline': "none"
          }
    }

    const ArrowDown ={
        position: 'fixed',
        bottom: '0',
        marginBottom: '1em',
        //width: '100%',
        //height: '0',
        borderRadius: '50%',
        border: 'none',
        outline:'none',
        zIndex: '2',
        "&:hover": {
            'outline': "none"
          }
    }






    return (
        <>
      


        
        <Fullpage>
            <FullpageContext.Consumer>
                {(ctx) => (
                    <>
                        {ctx.number > 0 &&

                        <div className="col-xs-12 col-md-12" style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                            <button type="button" className="btn btn-sq btn-clock btn-primary" data-togle= "modal" title={t("Shared.back")} style={ArrowUp} onClick={() => ctx.goto(ctx.slides[ctx.number -1], true)}> 
                                <span className="fa fa-chevron-circle-up" aria-hidden="true"></span>
                            </button>

                        </div>

                        }

                        {((ctx.number < 14 ) && (Dell + Lenovo ))&&
                        <div className="col-xs-12 col-md-12" style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                            <button type="button" className="btn btn-sq btn-clock btn-primary" data-togle= "modal" title={t("Shared.next")} style={ArrowDown} onClick={() => ctx.goto(ctx.slides[ctx.number +1], true)}> 
                                <span className="fa fa-chevron-circle-down" aria-hidden="true"></span>
                            </button>
                        </div>
                        }


                    <FullpageNavigation />
                    <FullPageSections>
                    <FullpageSection style={PageStyle}>
                            <StepR style={StepStyle}
                                title={t("Rebuild1.Title")}
                                desc={
                                    <div>
                                        <p>{t("Rebuild1.Desc")}</p>

                                        <div className="row" style={{    display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                            <div className="col">

                                            <button onClick={(ctx) => handleDell(ctx)} style={{ background: "#fff", border: 'none'}}>
                                                    <img border="0" alt="Dell" src="img/control/dell.png" width="100" height="100" />
                                                </button>
                                            </div>
                                            <div className="col" style={{ marginLeft: "10px"}}>
                                                <button onClick={(ctx) => HandleLenovo(ctx)} style={{ background: "#fff", border: 'none'}}>
                                                    <img border="0" alt="Lenovo" src="img/control/lenovo.svg" width="100" height="100" />
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    
                                }
                                img="img/rebuild/manideia.svg"
                                arrowdown="true"
                                arrowup="true"
                            />

                        </FullpageSection>




                        {Dell && 
                            <>
                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepL style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios1.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                        {t("DellBios1.Opt1")}
                                                            
                                                        </li>
                                                        <li>
                                                            {t("DellBios1.Opt2")}
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-0.png"

                                        />
                                </FullpageSection>

                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepR style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios2.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                        {t("DellBios2.Opt1")}
                                                            
                                                        </li>
                                                        <li>
                                                            {t("DellBios2.Opt2")}
                                                        </li>
                                                        <li>
                                                            {t("DellBios2.Opt3")}
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-1.png"

                                        />
                                </FullpageSection>

                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepL style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios3.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                        {t("DellBios3.Opt1")}
                                                            
                                                        </li>
                                                        <li>
                                                            {t("DellBios3.Opt2")}
                                                        </li>
                                                        <li>
                                                            {t("DellBios3.Opt3")}
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-2.png"

                                        />
                                </FullpageSection>

                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepR style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios4.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                        {t("DellBios4.Opt1")}
                                                            
                                                        </li>
        
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-3.png"

                                        />
                                </FullpageSection>
                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepL style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios5.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                        {t("DellBios5.Opt1")}
                                                            
                                                        </li>
        
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-4.png"

                                        />
                                </FullpageSection>
                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepR style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios6.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                        {t("DellBios6.Opt1")}
                                                            
                                                        </li>
        
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-5.png"

                                        />
                                </FullpageSection>
                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepL style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios7.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                            {t("DellBios7.Opt1")}
                                                            
                                                        </li>
                                                        <li>
                                                            {t("DellBios7.Opt2")}
                                                            
                                                        </li>
        
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-0.png"

                                        />
                                </FullpageSection>
                                <FullpageSection style={{ alignItems: 'center', justifyContent: 'center',paddingTop: '7em'}}>
                                    <StepR style={StepStyle}
                                        anchor={"#slide" + (ctx.number +1)}
                                        title={t("DellBios8.Title")}
                                        desc={<>
                                            <div className="dos-and-donts">
                                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                                {/* <h3>First Thing first</h3> */}
                                                <br />
                                                    <ol>
                                                        <li>
                                                            {t("DellBios8.Opt1")}                                                            
                                                        </li>        
                                                    </ol>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        img="img/rebuild/dell/dell-bios-6.png"

                                        />
                                </FullpageSection>
                                <StepsRebuild />
                                <EDMSetup ctx={ctx}/>

                            </>

                            
                        }

                    {Lenovo && 

                        <StepsRebuild />
                    }



                    </FullPageSections>
                    </>
                )}
            </FullpageContext.Consumer>
        </Fullpage>




        </>
        
      );
  };

