import axios from 'axios'
//require('dotenv/config');


axios.defaults.headers.common['ocp-apim-subscription-key'] = process.env.REACT_APP_APIM;
const api = axios.create({
    baseURL: process.env.REACT_APP_API
})



export default api
