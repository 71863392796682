import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18next'

ReactDOM.render(
  <Suspense fallback={<span style={{position: 'fixed', top: '50%', left: '50%', transform: "translate('-50%', '-50%')"}} className="loader-spinner loader-md fade in" id="loader"></span>}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
