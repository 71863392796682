// import {
//     ALERT_DETAILS,
//   } from "../actions/PatientActions";
  
const initialState = {

    details:{
      message: "",
      type: 'success',
      open: false
    }
  };
  
  const AlertReducer = function(state = initialState, action) {
    switch (action.type) {
      
      case 'ALERT_DETAILS': {
        // console.log(action)
        return {
          ...state,
          details: {...action.payload},
  
        }
        // console.log(action);
      }
  
      default: {
        return {
          ...state
        };
      }
    }
  };
  
  export default AlertReducer;
  