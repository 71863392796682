import React from "react";
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Header()  {
  const { t, i18n} = useTranslation()

  

  //const dispatch = useDispatch()
    function handleTranslation(e,lang) {
      e.preventDefault() 
      i18n.changeLanguage(lang)

    }

    function topFunction(e) {
      e.preventDefault()
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

  
  


    return (
      <header className="site-header">
        <nav className="navbar navbar-fixed-top">
          <ul className="nav navbar-nav navbar-left" onClick={(e) => topFunction(e)}>
            <Link to="/" className="active">
              <li>
                <div className="horizontal-logo"  title="Return home">
                  <div className="logo-image" title="NTT"></div>
                  <div className="logo-title">
                    <span>
                      I&T Onboarding
                    </span>
                  </div>
                </div>


            </li>
          </Link>
        </ul>


        <ul className="nav navbar-nav navbar-right">


          <li onClick={(e) => topFunction(e)}>
            <Link to="/" className="active">Home</Link>
          </li>
          <li role="separator" className="divider"></li>

          <li className="dropdown">
            <a data-toggle="dropdown" href="/" role="button" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle">
              <span className="fa fa-globe"></span>{t("Header.1")}<span className="caret"></span>
            </a>
            <ul className="dropdown-menu">
              <li><a href="#1" onClick={(e) => handleTranslation(e, "en-US")}>{t("Header.2")}</a></li>
              <li><a href="#2" onClick={(e) => handleTranslation(e, "pt-BR")}>{t("Header.3")}</a></li>
              <li><a href="#3" onClick={(e) => handleTranslation(e, "es-ES")}>{t("Header.4")}</a></li>
              {/* <li className="active"><a href="#">Active item</a></li> */}
            </ul>
          </li>

        </ul>
      </nav>
      </header>


      );
  };

