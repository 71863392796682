import React from "react";

import { FullpageSection } from '@ap.cx/react-fullpage'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import StepL from "./StepL"
import StepR from "./StepR"




export default function EDMSetup(object)  {

    const { t } = useTranslation()
    

    
    const StepStyle = {
        justifyContent: 'center', 
        alignItems: 'center'
    }

    const PageStyle = {

        alignItems: 'center', 
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
    

    return (
        <>     
            <FullpageSection style={PageStyle}>                
                <StepL style={StepStyle}
                    anchor={"#slide" + (object.ctx.number +1)}
                    title={t("Step2.Title")}
                    desc={
                        <ol>
                            <li>
                                {t("Step2.Desc1")} <b>{t("Shared.next")}</b>
                            </li>

                            <li>
                                {t("Step2.Desc2")} <b>{t("Shared.next")}</b>
                            </li>

                            <li>
                                {t("Step2.Desc3")}
                            </li>
                        </ol>
                    }
                    img="img/steps/Step2.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    anchor={"#slide" + (object.ctx.number +1)}
                    title={t("Step3.Title")}
                    desc={
                        <>
                            <p>{t("Step3.Desc1")}<Link to="/credentials"> {t("Shared.clickhere")}</Link>.</p>
                            <p>{t("Step3.Desc2")}
                                <span onClick={() => object.ctx.goto(object.ctx.slides[object.ctx.number + 21], true)} style={{ cursor: 'pointer', color: '#0fc8f2' }}>
                                    {t("Shared.clickhere")}
                                </span>
                            </p>
                        </>
                    }
                    img="img/steps/Step3.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    title={t("Step4.Title")}
                    desc={
                        <div>
                            <p><b>{t("Shared.preferredmethod")}</b>{t("Step4.Desc")}</p>
                            <p>
                                <b>{t("Shared.note")}</b>{t("Step4.Desc2")}
                                <span onClick={() => object.ctx.goto(object.ctx.slides[object.ctx.number + 4], true)} style={{ cursor: 'pointer', color: '#0fc8f2' }}>
                                    {t("Shared.clickhere")}
                                </span>
                            </p>

                            <div className="row" style={{    display: 'flex',justifyContent: 'space-around'}}>
                                <a title="Google Play" href="https://play.google.com/store/apps/details?id=com.azure.authenticator">
                                    <img border="0" alt="Google Play" src="img/control/play-store.png" width="100" height="100" />
                                </a>

                                <a title="App Store" href="https://apps.apple.com/br/app/microsoft-authenticator/id983156458">
                                    <img border="0" alt="AppStore" src="img/control/app-store.png" width="100" height="100" />
                                </a>
                            </div>  

                            <div className="dos-and-donts">
                                <div className="dotted-list do-list" style={{justifyContent:"center"}}>
                                    <br />
                                    <ol>
                                        <li>
                                            <>{t("Step4.Opt1")}</>                                              
                                        </li>

                                        <li>
                                            <>{t("Step4.Opt2")} <b>{t("Shared.next")}</b>.</>
                                        </li>

                                        <li>
                                            <>{t("Step4.Opt3")} <b>{t("Shared.next")}</b>.</>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        
                    }
                    img="img/steps/Step4.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    title={t("Step5.Title")}
                    desc={
                        <ol>
                            <li>
                                <>{t("Step5.Opt1")}</>
                            </li>

                            <li>
                                <>{t("Step5.Opt2")}</>
                            </li>

                            <li>
                                <>{t("Step5.Opt3")} <b>{t("Shared.next")}</b></>
                            </li>
                        </ol>
                    }
                    img="img/steps/Step5.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    title={t("Step6.Title")}
                    desc={
                        <div>
                            <p>{t("Step6.Desc")}</p>
                            <p>{t("Step6.Desc2")}<b>{t("Shared.next")}</b>.</p>
                        </div>
                    }
                    img="img/steps/Step6.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    title={t("Step7.Title")}
                    desc={
                        <>
                            <ol>
                                <li>
                                    {t("Step7.Opt1")} <b>{t("Shared.next")}</b>
                                </li>

                                <li>
                                    {t("Step7.Opt2")} <b>{t("Shared.done")}</b>
                                </li>

                                <li>
                                    {t("Step7.Opt3")}
                                </li>
                            </ol>                                  
                        </>
                    }
                    img="img/steps/Step7.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    title={t("Step8.Title")}
                    desc={
                        <div>
                            <p><b>{t("Shared.altmethod")}</b>{t("Step8.Desc")}</p>
                            <ol>
                                <li>
                                    {t("Step8.Opt1")}
                                </li>
                                
                                <li>
                                    {t("Step8.Opt2")} <b>{t("Shared.confirm")}</b>
                                </li>

                                <li>
                                    {t("Step8.Opt3")} <b>{t("Shared.next")}</b>
                                </li>

                                <li>
                                    {t("Step8.Opt4")} <b>{t("Shared.next")}</b>
                                </li>

                                <li>
                                    {t("Step8.Opt5")}
                                </li>
                            </ol>
                        </div>
                    }
                    img="img/steps/Step8.png"
                    arrowdown="true"
                    arrowup="true"
                />

            
            </FullpageSection>

            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    title={t("Step9.Title")}
                    desc={
                        <>
                            <p>{t("Step9.Desc")}</p>
                            <p>{t("Step9.Desc2")}
                                <span onClick={() => object.ctx.goto(object.ctx.slides[object.ctx.number + 16], true)} style={{ cursor: 'pointer', color: '#0fc8f2' }}>
                                    {t("Shared.clickhere")}
                                </span>
                            </p>
                        </>
                    }
                    img="img/steps/Step9.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    title={t("Step10.Title")}
                    desc={
                        <div>
                            <p>{t("Step10.Desc")}</p>
                        </div>
                    }
                    img="img/steps/Step10.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    title={t("Step11.Title")}
                    desc={
                        <div>
                            <p>{t("Step11.Desc")}</p>
                            
                        </div>
                    }
                    img="img/steps/Step11.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    title={t("Step12.Title")}
                    desc={
                        <div>
                            <p>{t("Step12.Desc")}</p>
                            <p>{t("Step12.Desc2")}<b>{t("Shared.getstarted")}</b>.</p>
                        </div>
                    }
                    img="img/steps/Step12.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    title={t("Step13.Title")}
                    desc={
                        <div>
                            <p>{t("Step13.Desc")}<b>{t("Shared.next")}</b>.</p>
                        </div>
                    }
                    img="img/steps/Step13.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    title={t("Step14.Title")}
                    desc={
                        <div>
                            <p>{t("Step14.Desc")}<a href="https://comms.services.global.ntt/GetYourNumericID" target="_blank" rel="noreferrer">{t("Shared.clickhere")}</a>.</p>
                        </div>
                    }
                    img="img/steps/Step14.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    title={t("Step15.Title")}
                    desc={
                        <div>
                            <p>{t("Step15.Desc")}</p>
                        </div>
                    }
                    img="img/steps/Step15.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepL style={StepStyle}
                    title={t("Step16.Title")}
                    desc={
                        <div>
                            <p>{t("Step16.Desc")}</p>
                        </div>
                    }
                    img="img/steps/Step16.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>


            <FullpageSection style={PageStyle}>
                <StepR style={StepStyle}
                    title={t("Step17.Title")}
                    desc={
                        <div>
                            <p>{t("Step17.Desc")}</p>
                        </div>
                    }
                    img="img/steps/Step17.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>   

            <FullpageSection style={PageStyle}>

                <StepL style={StepStyle}
                    title={t("Step18.Title")}
                    desc={
                    <div>
                        <p>{t("Step18.Desc")}</p>
                    </div>
                    }
                    img="img/steps/Step18.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>                   

            <FullpageSection style={PageStyle}>

                <StepR style={StepStyle}
                    title={t("Step19.Title")}
                    desc={
                    <div>
                        <p>{t("Step19.Desc1")}</p>
                        <p>{t("Step19.Desc2")}</p>
                        <p><b>{t("Shared.note")}</b>{t("Step19.Desc3")}</p>
                    </div>
                    }
                    img="img/steps/Step19.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection> 

            <FullpageSection style={PageStyle}>

                <StepL style={StepStyle}
                    title={t("Step20.Title")}
                    desc={
                    <div>
                        <p>{t("Step20.Desc")}</p>
                    </div>
                    }
                    img="img/steps/Step20.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection> 

            <FullpageSection style={PageStyle}>

                <StepR style={StepStyle}
                    title={t("Step21.Title")}
                    desc={
                    <div>
                        <p>{t("Step21.Desc")}<b>{t("Shared.finish")}</b>.</p>
                    </div>
                    }
                    img="img/steps/Step21.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection> 

            <FullpageSection style={PageStyle}>

                <StepL style={StepStyle}
                    title={t("Step22.Title")}
                    desc={
                    <div>
                        <p>{t("Step22.Desc")}</p>
                        <p>{t("Step22.Desc2")}</p>
                    </div>
                    }
                    img="img/steps/Step22.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>
            
            <FullpageSection style={PageStyle}>

                <StepR style={StepStyle}
                    title={t("Step23.Title")}
                    desc={
                    <div>
                        <p>{t("Step23.Desc")}</p>
                        <p>{t("Step23.Desc2")}</p>
                        <p>{t("Step23.Desc3")}</p>
                    </div>
                    }
                    img="img/steps/Step23.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>

            <FullpageSection style={PageStyle}>

                <StepL style={StepStyle}
                    title={t("Step24.Title")}
                    desc={
                    <div>
                        <p>{t("Step24.Desc")}</p>
                    </div>
                    }
                    img="img/steps/Step24.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>

            <FullpageSection style={PageStyle}>

                <StepR style={StepStyle}
                    title={t("Step25.Title")}
                    desc={
                    <div>
                        <p>{t("Step25.Desc")}</p>
                    </div>
                    }
                    img="img/steps/Step25.png"
                    arrowdown="true"
                    arrowup="true"
                />
            </FullpageSection>

                 
        </>
        
      );
  };

