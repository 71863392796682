import React from "react";
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'
import {Card, CardContent, Container} from '@material-ui/core';

import {getCredential} from '../../redux/actions/CredentialsActions'

import Modal from "./HelpModal"

export default function RecoverCreds()  {
    const { register, handleSubmit, errors } = useForm();
    const [modalShow, setModalShow] = React.useState(false);

    const { t } = useTranslation()



    const dispatch = useDispatch()


    function getCredentials(data) {
        if(data.passkey && data.userfname) {
            dispatch(getCredential(data))
        }

    }
    const onSubmit = data => getCredentials(data);


    function handleClose() {
        setModalShow(false)
    }
    

    const credentialDetail = useSelector(state => state.credential.credentialDetail) 

    const styles = {
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: "auto",
            transition: "0.3s",
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "&:hover": {
                boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
            }
        },
        loading: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: "auto",
            transition: "0.3s"
        },
        center: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        avatar: {
            height: '80px',
            width: '80px',
            margin: '20px',
            display: 'flex',
            overflow: 'hidden',
            position: 'relative',
            fontSize: '1.25rem',
            alignItems: 'center',
            flexShrink: '0',
            lineHeight: 1,
            userSelect: 'none',
            borderRadius: '50%',
            justifyContent: 'center',
        },
        avatarImage: {
            color: 'transparent',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            textAlign: 'center',
            textIndent: '10000px',
        }
    }




  
  


    return (
        <>
            <div className="grid-container" style={{paddingTop:"6em"}}>
                <div className="grid grid--2-cols gutter--md-xl gutter-row--md">
                    <div className="column--xs-2 column--md-1">
                        <img className="img" src="img/pass.svg"  alt="Password Recovery"/>
                    </div>

                    {credentialDetail.loading && 
                        <div style={styles.loading} className="column--xs-2 column--md-1">
                            <div style={styles.center} >
                        
                                <span style={{left: '40%'}} className="loader-spinner loader-md fade in" id="loader" />
                            </div>
                        </div>
                    }
                    

                    
                    
                    {credentialDetail.username &&
                       

                        <Card style={styles.card} className="column--xs-2 column--md-1">

                            <Container style={styles.center}>
                                <div style={styles.avatar}>
                                    <img
                                        alt="NTT"
                                        src={'img/NTT-blue-square.png'}
                                        style={styles.avatarImage}
                                    
                                    />
                                </div>
                                <b><span>{credentialDetail.displayname}</span></b>
                                <span>{credentialDetail.role}</span>
                            </Container>
                            <CardContent style={styles.center}>

                            <div>
                                
                                <b><span>{t("RecCredentials.Mod_Mail")}</span></b>
                                <br />
                                <span>{credentialDetail.username}</span>
                                
                                <br />
                                <br />
                                <b><span>{t("RecCredentials.Mod_Pass")}</span></b>
                                <br />
                                <p>{credentialDetail.password}</p>
                                <br />  
                                <b><h6 style={{'fontSize':'small'}}>{t("RecCredentials.Mod_Subtitle")}</h6></b>
                                <br />
                                <Link to={{ pathname: "https://myprofile.microsoft.com" }} target="_blank">
                                    <button type="button" className="btn btn-secondary btn-block bottom-gutter">
                                        <span className="fa fa-lock" aria-hidden="true"></span>
                                        <span>{t("RecCredentials.ResetPass")}</span>
                                    </button>
                                </Link>
                                <Link to="/welcome">
                                    <button type="button" className="btn btn-info btn-block bottom-gutter">
                                        <span className="fa fa-laptop" aria-hidden="true"></span>
                                        <span>{t("RecCredentials.SetupLaptop")}</span>
                                        
                                    </button>
                                </Link>
                            </div>

                            </CardContent>
                        </Card>
                    }
                    
                   
                    {!credentialDetail.loading && !credentialDetail.username && 
                        <form onSubmit={handleSubmit(onSubmit)} className="column--xs-2 column--md-1">
                            <div className='form-group has-icon'>
                                <label className="control-label" htmlFor="exampleTextInput1">{t("RecCredentials.FName")}
                                    <span className="tooltip-info" data-toggle="tooltip" title={t("RecCredentials.Mod_FirstName")}></span>
                                </label>

                                <input
                                    className="form-control"
                                    placeholder={t("RecCredentials.FName_desc")} 
                                    name="userfname"
                                    maxLength="15"
                                    ref={register({
                                        required: t("Shared.required"),
                                    }
                                )}
                                />
                                <span className="form-control-feedback fa-user"></span>
                                {errors.userfname && <div className="help-block" data-validation-error id="validationExamplePattern1Help">{errors.userfname.message}</div>}
                                
                            </div> 


                            <div className='form-group has-icon'>
                                <label className="control-label" htmlFor="exampleTextInput1">{t("RecCredentials.LapSN")}
                                    <span className="tooltip-info" data-toggle="tooltip" title={t("RecCredentials.Mod_Title")} onClick={() => setModalShow(true)}></span>
                                </label>
                                <input
                                    className="form-control"
                                    name="passkey"
                                    placeholder={t("RecCredentials.LapSN_Desc")}
                                    maxLength="10"
                                    ref={register({
                                    required: t("Shared.required"),
                                    validate: value => value.length >= 5 & value.length < 10 || "Serial number must has more then 5 and less than 10 chars",
                                    })}
                                />
                                <span className="form-control-feedback fa-user"></span>
                                {errors.passkey && <div className="help-block" data-validation-error id="validationExamplePattern2Help">{errors.passkey.message}</div>}
                            </div> 
                            {credentialDetail.error && 
                                <>
                                    <span><b>{t("RecCredentials.Mod_Error")}</b></span>
                                    <br />
                                </>
                            }




                            <div className="column--xs-2 column--md-1">
                                    <button type="submit" className="btn btn-block btn-primary">{t("Shared.submit")}</button>
                            </div>
                        </form>
                    }




                </div>
                <Modal
                    credentialDetail={credentialDetail}
                    modalShow={modalShow}
                    handleClose={handleClose}
                />


            </div>


                        

        </>
        
      );
  };

